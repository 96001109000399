<template>
  <div class="box">
    <img class="img" src="../../assets/shouye2_banner333.png" alt="" />
    <div class="mask"></div>
    <div class="content">
      <img src="../../assets/denglu_imgs@3x.png" class="content_img" alt="" />
      <div class="rigth">
        <img class="logo" src="../../assets/logo.png" alt="" />
        <div class="tab">
          <div class="item active">微信登录</div>
          <div class="item" @click="gologin">密码登录</div>
          <div class="item" @click="goregister">手机号注册</div>
        </div>
        <!-- <div @click="go">123456789</div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {};
  },
  mounted() {
    let that = this;
    if (this.$route.query.code) {
      this.getweChatLogin({
        code: this.$route.query.code,
      }).then((res) => {
        this.$message({
          message: res.msg,
          type: res.code == 200 ? "success" : "cancel",
        });
        if (res.code == 200) {
          let num = Math.round(Math.random() * 42);
          this.$store.commit("STE_HUMAN", num);
          this.$store.commit("SET_LEARNING", 2);
          this.$store.commit("SET_forcetoexit", new Date().getTime());
          this.getsmsVerificationr({
            id: res.data.id,
            verify: "88888",
            phone: res.data.phone,
            username: res.data.username,
            type: 2,
          });
          if (this.$route.query.type == 1) {
            this.$router.go(-1);
          } else {
            this.$router.push("/Layout/Learningcenter");
          }
        } else if (res.code == 500) {
          this.$message.error(res.msg);
          setTimeout(() => {
            that.$store.commit("SET_WX", res.data); //保存登录信息
            that.$router.push("/bindPhone");
          }, 1000);
        }
      });
    } else {
      this.go();
    }
  },
  methods: {
    ...mapActions(["getweChatLogin", "getsmsVerificationr"]),
    go() {
      //var url = encodeURIComponent("http://website.zpt.udao.sh.cn/wxlogin");
      var url = encodeURIComponent("http://www.zpton.com/wxlogin");
      window.location.href =
        "https://open.weixin.qq.com/connect/qrconnect?appid=wx328cd8a32288fd0a&redirect_uri=" +
        url +
        "&response_type=code&scope=snsapi_login&state=01234#wechat_redirect";
    },
    gologin() {
      this.$router.push("/login");
    },
    goregister() {
      this.$router.push("/register");
    },
  },
};
</script>

<style lang="scss" scoped>
input:focus {
  outline: none;
  border: 0 none;
}

.box {
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100vh;
  position: relative;
  .img {
    width: 1922px;
    height: 531px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
  }
  .mask {
    width: 1922px;
    height: 531px;
    background: rgba($color: #000000, $alpha: 0.3);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1001;
  }
  .content {
    display: flex;
    width: 786px;
    height: 448px;
    background: #ffffff;
    box-shadow: 0px 5px 20px 0px #06316a;
    border-radius: 10px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1002;
    .content_img {
      width: 318px;
      height: 449px;
      border-radius: 10px 0px 0px 10px;
      vertical-align: middle;
    }
    .rigth {
      flex: 1;
      padding: 36px 64px;
      box-sizing: border-box;
      .logo {
        width: 143px;
        height: 48px;
      }
      .tab {
        margin-top: 42px;
        display: flex;
        justify-content: space-between;

        .item {
          cursor: pointer;
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #444444;
        }
        .active {
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #1276fb;
        }
      }
      .phone {
        margin-top: 41px;
        padding-bottom: 5px;
        border-bottom: 1px solid #d4d9e2;
        input {
          &::placeholder {
            font-size: 16px;
          }
          margin-left: 13px;
          transform: translateY(-3px);
          font-size: 20px;
          border: 0 none;
          height: 30px;
        }
      }
      .password {
        margin-top: 30px;
        padding-bottom: 5px;
        border-bottom: 1px solid #d4d9e2;
        input {
          &::placeholder {
            font-size: 16px;
          }
          width: 270px;
          margin-left: 13px;
          transform: translateY(-3px);
          font-size: 20px;
          border: 0 none;
          height: 30px;
        }
      }
      .btn {
        cursor: pointer;
        margin-top: 37px;
        text-align: center;
        line-height: 47px;
        height: 47px;
        background: #1276fb;
        border-radius: 24px;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
      }
      .tac {
        margin-top: 8px;
        text-align: center;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #9c9c9c;
      }
    }
  }
}
</style>
